.PackagingWorkshopImg{
    background-image: url('../images/pw.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    width: 1000px;
    background-position: center;
    border-radius: 20px;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&family=Permanent+Marker&display=swap");

* {
  box-sizing: border-box;
}

.ye-div{
  display: flex;
  justify-content: center;
  margin-top: 70px;
  height: 100%;
  animation: hue-rotate 10s linear infinite;
}

.button {
  -webkit-font-smoothing: antialiased;
  background-color: black;
  border: none;
  color: #fff;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 100;
  text-decoration: none;
  user-select: none;
  letter-spacing: 1px;
  color: white;
  padding: 20px 40px;
  text-transform: uppercase;
  transition: all 0.1s ease-out;
  border-radius: 30px;
  font-family: "Permanent Marker", cursive;

  &:hover {
    background-color: black;
    color: #fff;
  }
  
  &:active {
    transform: scale(0.95);
  }

  &--bubble {
    position: relative;
    z-index: 2;
    color: white;
    background: none;

    &:hover {
      background: none;
    }

    &:hover + .button--bubble__effect-container .circle {
    //   background: darken(black, 15%);
    }

    &:hover + .button--bubble__effect-container .button {
    //   background: darken(black, 15%);
    }

    &:active + .button--bubble__effect-container {
      transform: scale(0.95);
    }

    &__container {
      position: relative;
      display: inline-block;

      .effect-button {
        position: absolute;
        width: 50%;
        height: 25%;
        top: 50%;
        left: 25%;
        z-index: 1;
        transform: translateY(-50%);
        background: black;
        transition: black 0.1s ease-out;
      }
    }
  }
}

.button--bubble__effect-container {
  position: absolute;
  display: block;
  width: 200%;
  height: 400%;
  top: -150%;
  left: -50%;
  -webkit-filter: url("#goo");
  filter: url("#goo");
  transition: all 0.1s ease-out;
  pointer-events: none;

  .circle {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    background: black;
    transition: black 0.1s ease-out;

    &.top-left {
        top: 40%;
        left: 27%;
    }

    &.bottom-right {
        bottom: 40%;
        right: 27%;
    }
  }
}

.goo {
  position: absolute;
  visibility: hidden;
  width: 1px;
  height: 1px;
}


.button--bubble__container {
  top: 50%;
  margin-top: -25px;
}

@keyframes hue-rotate {
      from {
        -webkit-filter: hue-rotate(0);
        -moz-filter: hue-rotate(0);
        -ms-filter: hue-rotate(0);
        filter: hue-rotate(0);
      }
      to {
        -webkit-filter: hue-rotate(360deg);
        -moz-filter: hue-rotate(360deg);
        -ms-filter: hue-rotate(360deg);
        filter: hue-rotate(360deg);
      }
}
@media(max-width:1030px){
    .PackagingWorkshopImg{
        height: 400px;
        width: 900px;
    }
}
@media(max-width:935px){
    .PackagingWorkshopImg{
        height: 400px;
        width: 800px;
    }
}
@media(max-width:833px){
    .PackagingWorkshopImg{
        height: 400px;
        width: 700px;
    }
}
@media(max-width:735px){
    .PackagingWorkshopImg{
        height: 300px;
        width: 600px;
    }
}
@media(max-width:620px){
    .PackagingWorkshopImg{
        height: 300px;
        width: 500px;
    }
}
@media(max-width:530px){
    .PackagingWorkshopImg{
        height: 250px;
        width: 400px;
    }
}
@media(max-width:430px){
    .PackagingWorkshopImg{
        height: 250px;
        width: 350px;
    }
}