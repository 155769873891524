@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&family=Permanent+Marker&display=swap");

@font-face {
  font-family: rooney;
  src: url(../font/rooney/RooneyPro-Heavy.ttf);
}
@font-face {
    font-family: gilroy;
    src: url(../font/gilroy/Gilroy-Regular.ttf);
  }
  .bbt-head-pro{
    line-height: 50px;
  }
.small-head-bbt{
    color: rgba(73, 104, 0, 1);
    font-family: rooney;
    font-size: 25px;
    text-align: center;
    margin-top: 40px;
}
.head-bbt{
    font-family: rooney;
    font-size: 40px;
    text-align: center;
}
.project-brief{
    width: 900px;
    margin: auto;
}
.project-brief-head{
    font-family: rooney;
    font-size: 25px;
}
.date-week-dot{
    display: flex;
    align-items: center;
}
.date-week-dot-single{
    color: rgba(73, 104, 0, 1);
    font-family: "Permanent Marker", cursive;
    letter-spacing: 3px;
    font-size: 15px;
    font-weight: 100
}
.fst{
    height: 2.5px;
    width: 2.5px;
    border-radius: 50%;
    background-color: black;
    margin-top: -15px;
    margin-left: 5px;
    margin-right: 5px;
}
.bbt-head-sub{
    font-family: gilroy;
    font-size: 18px;
    font-weight: 700;
    color: rgba(91, 91, 91, 1);
}
.bbt-para{
    font-family: gilroy;
    color: rgba(91, 91, 91, 1);
    line-height: 180%;
}
.bbt-risk{
    font-family: gilroy;
    font-weight: 700;
}
.bbt-second{
    width: 900px;
    margin: auto;
}
.bbt-second-head{
    font-family: rooney;
    font-size: 20px;
}
.bbt-second-para-1{
    font-family: gilroy;
    color: rgba(91, 91, 91, 1);
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 180%;
}
.bbt-second-para-2, .bbt-second-para-3{
    font-family: gilroy;
    color: rgba(91, 91, 91, 1);
    font-size: 16px;
    line-height: 180%;
}
.bbt-para-dark{
    font-weight: 600;
}
.bbt-obj{
    font-family: gilroy;
    font-size: 16px;
    font-weight: 700;
    line-height: 180%;
}
.bbt-obj-para{
    font-family: gilroy;
    color: rgba(91, 91, 91, 1);
    font-size: 16px;
    line-height: 180%;
}

.task-flow{
    width: 900px;
    margin: auto;
}
.insight{
    width: 900px;
}
.insight-box-hash{
    background-color: rgba(198, 180, 255, 1);
    height: 45px;
    display: flex;
    border-radius: 10px;
    margin-bottom: 20px;
}
.insight-head{
    font-family: rooney;
    margin: 10px 10px;
}
.in-para{
    color: rgba(91, 91, 91, 1);
    font-family: gilroy;
    line-height: 160%;
}
.bbt-para-dark-in{
    font-weight: 600;
    color: rgba(91, 91, 91, 1);
    font-family: gilroy;
}
.card-sorting{
    width: 900px;
    margin: auto;
}
.cs-obj{
    font-family: gilroy;
    font-weight: 600;
}
.proj-part-img-4{
    height: 600px;
}
.gr1-img-1{
    height: 25vw;
}
@media(max-width:1025px){
    .insight{
        width: auto;
        margin: 0 10%;
    }
}
@media(max-width: 970px){
    .proj-part-img-4{
        height: 550px;
    }
}
@media(max-width: 888px){
    .proj-part-img-4{
        height: 500px;
    }
}
@media(max-width: 803px){
    .proj-part-img-4{
        height: 450px;
    }
}
@media(max-width: 723px){
    .proj-part-img-4{
        height: 400px;
    }
}
@media(max-width: 648px){
    .proj-part-img-4{
        height: 350px;
    }
}
@media(max-width: 566px){
    .proj-part-img-4{
        height: 300px;
    }
}
@media(max-width: 483px){
    .proj-part-img-4{
        height: 250px;
    }
}
@media(max-width: 404px){
    .proj-part-img-4{
        height: 220px;
    }
}
@media(max-width: 401px){
    .insight-box-hash{
        height: 65px;
    }
}