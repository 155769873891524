@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&family=Permanent+Marker&display=swap");

@font-face {
  font-family: rooney;
  src: url(../font/rooney/RooneyPro-Heavy.ttf);
}
@font-face {
    font-family: gilroy;
    src: url(../font/gilroy/Gilroy-Regular.ttf);
  }
  .lh-head-bbt{
    line-height: 45px;
  }
  .tt-tt-img-2, .tt-tt-img-3{
    height: 380px;
    width: 430px;
    margin: 0 20px;
  }
.tt-button{
    margin: auto;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(45, 38, 34, 1);
    width: 200px;
    height: 40px;
    border-radius: 20px;
    color: white;
    font-family: "Permanent Marker", cursive;
    cursor: pointer;
}


@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&family=Permanent+Marker&display=swap");
// black: #222;
// $green: #90feb5;
// $action-color: $green;

* {
  box-sizing: border-box;
}
.ye-div{
  display: flex;
  justify-content: center;
  margin-top: 70px;
  height: 100%;
  animation: hue-rotate 10s linear infinite;
}

.button {
  -webkit-font-smoothing: antialiased;
  background-color: black;
  border: none;
  color: #fff;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 100;
  text-decoration: none;
  user-select: none;
  letter-spacing: 1px;
  color: white;
  padding: 20px 40px;
  text-transform: uppercase;
  transition: all 0.1s ease-out;
  border-radius: 30px;
  font-family: "Permanent Marker", cursive;

  &:hover {
    background-color: black;
    color: #fff;
  }
  
  &:active {
    transform: scale(0.95);
  }

  &--bubble {
    position: relative;
    z-index: 2;
    color: white;
    background: none;

    &:hover {
      background: black;
    }

    &:hover + .button--bubble__effect-container .circle {
      // background: darken($action-color, 15%);
    }

    &:hover + .button--bubble__effect-container .button {
      // background: darken($action-color, 15%);
    }

    &:active + .button--bubble__effect-container {
      transform: scale(0.95);
    }

    &__container {
      position: relative;
      display: inline-block;

      .effect-button {
        position: absolute;
        width: 50%;
        height: 25%;
        top: 50%;
        left: 25%;
        z-index: 1;
        transform: translateY(-50%);
        background: black;
        transition: black 0.1s ease-out;
      }
    }
  }
}

.button--bubble__effect-container {
  position: absolute;
  display: block;
  width: 200%;
  height: 400%;
  top: -150%;
  left: -50%;
  -webkit-filter: url("#goo");
  filter: url("#goo");
  transition: all 0.1s ease-out;
  pointer-events: none;

  .circle {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    background: black;
    transition: black 0.1s ease-out;

    &.top-left {
        top: 40%;
        left: 27%;
    }

    &.bottom-right {
        bottom: 40%;
        right: 27%;
    }
  }
}

.goo {
  position: absolute;
  visibility: hidden;
  width: 1px;
  height: 1px;
}


.button--bubble__container {
  top: 50%;
  margin-top: -25px;
}

@keyframes hue-rotate {
      from {
        -webkit-filter: hue-rotate(0);
        -moz-filter: hue-rotate(0);
        -ms-filter: hue-rotate(0);
        filter: hue-rotate(0);
      }
      to {
        -webkit-filter: hue-rotate(360deg);
        -moz-filter: hue-rotate(360deg);
        -ms-filter: hue-rotate(360deg);
        filter: hue-rotate(360deg);
      }
}

@media(max-width:873px){
  .tt-tt-img-2, .tt-tt-img-3{
    height: 350px;
    margin: 0 20px;
  }
}
@media(max-width:811px){
  .tt-tt-img-2, .tt-tt-img-3{
    height: 320px;
    margin: 0 20px;
  }
}
@media(max-width:750px){
  .tt-tt-img-2, .tt-tt-img-3{
    height: 290px;
    margin: 0 20px;
  }
}
@media(max-width:686px){
  .tt-img-div{
    width: 90%;
    margin: auto;
    margin-top: -30px;
  }
  .tt-tt-img-2, .tt-tt-img-3{
    height: 260px;
    margin: 0 20px;
  }
}
@media(max-width:632px){
  .tt-tt-img-2, .tt-tt-img-3{
    height: 230px;
    margin: 0 20px;
  }
}
@media(max-width:574px){
  .tt-tt-img-2, .tt-tt-img-3{
    height: 200px;
    margin: 0 20px;
  }
}
@media(max-width:521px){
  .tt-tt-img-2, .tt-tt-img-3{
    height: 170px;
    margin: 0 10px;
  }
}
@media(max-width:497px){
  .ye-div{
    width: 300px;
    margin: auto;
  }
  .bubble-wala-button{
    width: 251px;
    height: 60px;
  }
}
@media(max-width:494px){
  .ye-div{
    transform: scale(0.8);
  }
}
@media(max-width:489px){
  .ye-div{
    width: 251px;
    margin: auto;
  }
  .bubble-wala-button{
    height: 60px;
  }
}
@media(max-width:433px){
  .tt-tt-img-2, .tt-tt-img-3{
    height: 150px;
    margin: 0 10px;
  }
}
@media(max-width:400px){
  .ye-div{
    transform: scale(0.7);
  }
}
