@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&family=Permanent+Marker&display=swap");

@font-face {
  font-family: rooney;
  src: url(../font/rooney/RooneyPro-Heavy.ttf);
}
@font-face {
  font-family: gilroy;
  src: url(../font/gilroy/Gilroy-Regular.ttf);
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {
  height: 360px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.slider .slide-track {
  -webkit-animation: scroll 30s linear infinite;
  animation: scroll 30s linear infinite;
  display: flex;
  width: calc(250px * 11);
  justify-content: space-around;
}

.slider .slide img {
  width: auto;
  height: 300px;
  border-radius: 8px;
  margin: 35px 0;
}
.slider .slide1 img {
  width: auto;
  height: 360px;
  border-radius: 8px;
}
@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

// $dark-b
// $green: #90feb5;
// $action-color: $green;

* {
  box-sizing: border-box;
}
.homii {
  background: url(../images/untitled-copy-copy-copy-copy.gif);
  background-color: rgba(8, 0, 36, 1);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom 40px right 620px;
}
.hover-on-me{
  position: absolute;
  right: 4vw;
  bottom: 100px;
}
.img-left {
  height: 320px;
  width: auto;
  margin-left: 5%;
  position: relative;
  z-index: 2;
}
.spline-char {
  transform: scale(0.9);
}
.spline-char-2 {
  display: none;
}
.ye-div-ea {
  display: flex;
  justify-content: center;
  margin-top: 70px;
  height: 100%;
}
.ye-div-2 {
  margin-top: 30px;
  height: 100%;
}

.butto {
  -webkit-font-smoothing: antialiased;
  background-color: #fce25a;
  border: none;
  color: black;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 100;
  text-decoration: none;
  user-select: none;
  letter-spacing: 1px;
  color: black;
  padding: 20px 40px;
  text-transform: uppercase;
  transition: all 0.1s ease-out;
  border-radius: 30px;
  font-family: "Permanent Marker", cursive;

  &:hover {
    background-color: #fce25a;
    color: black;
  }

  &:active {
    transform: scale(0.95);
  }

  &--bubbl {
    position: relative;
    z-index: 2;
    color: black;
    background: #fce25a;

    &:hover {
      background: #fce25a;
    }

    &:hover + .butto--bubble__effect-container .circle {
      // background: darken($dark-blue, 15%);
    }

    &:hover + .butto--bubble__effect-container .butto {
      // background: darken($dark-blue, 15%);
    }

    &:active + .butto--bubble__effect-container {
      transform: scale(0.75);
    }

    &__containe {
      position: relative;
      display: inline-block;

      .effect-butto {
        position: absolute;
        width: 50%;
        height: 25%;
        top: 50%;
        left: 25%;
        z-index: 1;
        transform: translateY(-50%);
        background: #fce25a;
        transition: #fce25a 0.1s ease-out;
      }
    }
  }
}


.butto--bubbl__effect-containe {
  position: absolute;
  display: block;
  width: 200%;
  height: 400%;
  top: -150%;
  left: -50%;
  -webkit-filter: url("#goo");
  filter: url("#goo");
  transition: all 0.1s ease-out;
  pointer-events: none;

  .circl {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    background: #fce25a;
    transition: #fce25a 0.1s ease-out;

    &.top-left {
      top: 40%;
      left: 27%;
    }

    &.bottom-right {
      bottom: 40%;
      right: 27%;
    }
  }
}

.goo {
  position: absolute;
  visibility: hidden;
  width: 1px;
  height: 1px;
}

.butto--bubbl__containe {
  top: 50%;
  margin-top: -25px;
}

@keyframes hue-rotate {
  from {
    -webkit-filter: hue-rotate(0);
    -moz-filter: hue-rotate(0);
    -ms-filter: hue-rotate(0);
    filter: hue-rotate(0);
  }
  to {
    -webkit-filter: hue-rotate(360deg);
    -moz-filter: hue-rotate(360deg);
    -ms-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg);
  }
}

.Home-top {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
}
.img-card-left {
  background-color: transparent;
}
.img-card-right {
  background-color: transparent;
}
.img-right {
  color: #eee;
  padding-top: 30px;
  font-family: "Coiny", cursive;
  text-align: center;
  font-size: 70px;
  animation: bounce 1s ease infinite alternate;
  display: none;
}
.what-i-do {
  text-align: center;
  width: 750px;
  margin: auto;
  padding: 100px 0;
  margin-top: 10px;
}
.heading-work-wid {
  font-family: rooney;
  font-size: 82px;
  font-weight: bolder;
  line-height: 97%;
}

.body-wave {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 40vh;
  background-color: rgba(8, 0, 36, 1);
  font-family: Roboto;
  overflow: hidden;

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    min-width: 300vw;
    min-height: 300vw;
    background-color: #ffffff;
    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &:before {
    bottom: 15vh;
    border-radius: 45%;
    animation-duration: 10s;
  }

  &:after {
    bottom: 12vh;
    opacity: 0.5;
    border-radius: 47%;
    animation-duration: 10s;
  }
}

@keyframes rotate {
  0% {
    transform: translate(-50%, 0) rotateZ(0deg);
  }
  50% {
    transform: translate(-50%, -2%) rotateZ(180deg);
  }
  100% {
    transform: translate(-50%, 0%) rotateZ(360deg);
  }
}

@keyframes bounce {
  0% {
    text-shadow: 0 5px 0 #ccc, 0 2px 3px rgba(0, 0, 0, 1);
  }
  100% {
    transform: translateY(-20px);
    text-shadow: 0 50px 0, 0 0px 20px rgba(0, 0, 0, 0.8);
  }
}
.works {
  background-color: rgba(8, 0, 37, 1);
  /* height: 100vh; */
}
.my-work {
  color: rgba(248, 219, 70, 1);
  font-family: "Permanent Marker", cursive;
  font-size: 20px;
}
.heading-work {
  color: rgba(222, 222, 222, 1);
  font-family: rooney;
  font-size: 60px;
}
.card-work {
  margin: auto;
  height: 420px;
  width: 74%;
  background-color: rgba(248, 219, 70, 1);
  border-radius: 30px;
  background-image: url("../images/Frame\ 70.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.card-work {
  display: flex;
  padding: 55px;
}
.ui-ux {
  font-family: "Permanent Marker", cursive;
  font-weight: lighter;
}
.name-text-part {
  font-family: rooney;
  font-size: 48px;
  font-weight: 900;
}
.para-text-part {
  font-family: gilroy;
}
.span-text {
  font-family: rooney;
  font-weight: 900;
  display: flex;
}
.dot {
  margin: 0 12px;
  margin-top: 8px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: rgba(128, 128, 128, 0.448);
}
.check-detail {
  background-color: rgba(45, 38, 34, 1);
  color: white;
  font-family: gilroy;
  height: 43px;
  border: none;
  font-size: 16px;
  border-radius: 25px;
  width: 130px;
  margin-top: 20px;
}
.check-detail:hover {
  background-color: rgba(45, 38, 34, 0.85);
}
.check-detail2:hover {
  background-color: rgba(45, 38, 34, 0.85);
}
.check-detail3:hover {
  background-color: rgba(45, 38, 34, 0.85);
}
.next-cards {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card2,
.card3 {
  height: 380px;
  width: 555px;
  border-radius: 40px;
  margin: 30px 10px;
  padding-top: 50px;
  padding-left: 50px;
}
.card2 {
  background-color: rgba(153, 141, 248, 1);
  display: flex;
  overflow: auto;
  overflow-y: hidden;
  overflow-x: hidden;
}
.imgPartSsmall2 {
  height: 150px;
  margin-top: 210px;
  margin-right: -30px;
}
.card3 {
  background-color: rgba(249, 249, 249, 1);
  display: flex;
  overflow: auto;
  overflow-y: hidden;
  overflow-x: hidden;
}
.imgPartSsmall3 {
  height: 150px;
  margin-top: 210px;
  margin-right: -30px;
}
.check-detail2 {
  background-color: rgba(45, 38, 34, 1);
  color: white;
  font-family: gilroy;
  height: 43px;
  border: none;
  font-size: 16px;
  border-radius: 25px;
  width: 130px;
  margin-top: 20px;
}

.check-detail3 {
  background-color: rgba(45, 38, 34, 1);
  color: white;
  font-family: gilroy;
  height: 43px;
  border: none;
  font-size: 16px;
  border-radius: 25px;
  width: 130px;
  margin-top: 20px;
}
.ui-ux2 {
  font-family: "Permanent Marker", cursive;
  font-weight: lighter;
}
.name-text-part2 {
  font-family: rooney;
  font-size: 48px;
  font-weight: 900;
}
.para-text-part2 {
  font-family: gilroy;
}
.span-text2 {
  font-family: rooney;
  font-weight: 900;
  display: flex;
}
.dot2 {
  margin: 0 12px;
  margin-top: 8px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: rgba(128, 128, 128, 0.448);
}

.ui-ux3 {
  font-family: "Permanent Marker", cursive;
  font-weight: lighter;
}
.name-text-part3 {
  font-family: rooney;
  font-size: 48px;
  font-weight: 900;
}
.para-text-part3 {
  font-family: gilroy;
}
.span-text3 {
  font-family: rooney;
  font-weight: 900;
  display: flex;
}
.dot3 {
  margin: 0 12px;
  margin-top: 8px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: rgba(128, 128, 128, 0.448);
  display: block;
}
.below-caro{
  width: auto;
  margin: 0 25px;
  text-align: center;
  font-size: 26px;
  color: rgba(217, 217, 217, 0.5);
  font-family: gilroy;
  margin-bottom: 50px;
}
.exploreAll {
  background-color: rgba(248, 219, 70, 1);
  color: black;
  font-family: "Permanent Marker", cursive;
  height: 43px;
  border: none;
  font-size: 14px;
  border-radius: 25px;
  width: 150px;
  margin-top: 20px;
}

.exploration {
  background-color: rgba(24, 14, 57, 1);
}
.card-carousal-type {
  margin: auto;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  scroll-behavior: smooth;
  width: 90%;
}
.cc-cc-img1 {
  height: 340px;
}
.cc-cc-img2 {
  height: 420px;
}

//scroll bar
.card-carousal-type::-webkit-scrollbar-track {
  margin: 100px;
}
.card-carousal-type::-webkit-scrollbar {
  width: 30px;
  height: 5px;
}
.card-carousal-type::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ffffff38;
}
.card-work::-webkit-scrollbar-track {
  margin: 100px;
}
.card-work::-webkit-scrollbar {
  width: 30px;
  height: 5px;
}
.card-work::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ffffff38;
}

.make-sense {
  background-color: rgba(8, 0, 37, 1);
}
.above-links {
  background-color: rgba(24, 14, 57, 1);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;
}
.above-links-sub {
  margin-left: 100px;
  width: 550px;
}
.lhighl {
  height: 520px;
  padding-top: 50px;
}
.lhighl1 {
  display: none;
  height: 400px;
}
.footer {
  background-color: white;
  height: 15vh;
}
.links {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: gilroy;
  font-weight: 900;
}
.dot-foot {
  height: 3px;
  width: 3px;
  background-color: black;
  border-radius: 50%;
  margin-top: -12px;
  margin-left: 15px;
  margin-right: 15px;
}
.ht {
  text-decoration: none;
  color: black;
  margin-top: -14px;
}
.nothing {
  background-color: rgba(8, 0, 37, 1);
  height: 5vh;
}

.wave-img-text {
  height: 53vw;
  width: 100%;
}
.work-in {
  margin-left: 12.8vw;
  margin-bottom: 50px;
  line-height: 100%;
}
.subhs-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.port1 {
  height: 400px;
}
.port2 {
  height: 320px;
}
.port3 {
  height: 320px;
}
.toggle-img {
  display: none;
}
.subhs-img2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.port11 {
  text-align: center;
}
.subhs-img-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}
// @media(max-width:1415px){
//   .span-text{
//     font-size: 8px;
//   }
// }
.splineLoad{
  display: flex;
  justify-content: center;
  align-items: center;
}
@media(max-width:1436px){
  .hover-on-me{
    right: 3.5vw;
  }
}

@media (max-width: 1408px) {
  .span-text{
    // transform: scale(0.8);
  }
  .above-links-sub {
    width: 500px;
  }
  .lhighl {
    margin-left: -100px;
  }
}
@media(max-width:1396px){
  .hover-on-me{
    right: 2.5vw;
  }
}
@media(max-width:1338px){
  .hover-on-me{
    right: 1.5vw;
  }
}
@media (max-width: 1254px) {
  .img-left {
    margin-left: 100px;
    width: 465px;
    height: auto;
  }
}
@media(max-width:1242px){
  .hover-on-me{
    right: 1vw;
  }
}
@media (max-width: 1238px) {
  .above-links-sub {
    margin-left: 80px;
  }
  .lhighl {
    margin-left: -140px;
  }
}
@media (max-width: 1235px) {
  .spline-char {
    transform: scale(0.8);
  }
  .img-left {
    margin-left: 120px;
  }
  .card-work {
    height: 420px;
    width: 74%;
  }
  .card2,
  .card3 {
    width: 500px;
  }
}
@media (max-width: 1195px) {
  .port1 {
    height: 360px;
  }
  .port2 {
    height: 280px;
  }
  .port3 {
    height: 280px;
  }
}
@media (max-width: 1170px) {
  .img-left {
    margin-left: 80px;
  }
  .spline-char {
    margin-right: 0px;
  }
}
@media (max-width: 1134px) {
  .spline-char {
    transform: scale(0.7);
  }
}
@media (max-width: 1120px) {
  .lhighl {
    margin-left: -180px;
  }
}
@media (max-width: 1088px) {
  .lhighl {
    margin-left: -200px;
  }
}
@media (max-width: 1083px) {
  .spline-char {
    transform: scale(0.65);
  }
}
@media (max-width: 1080px) {
  .port1 {
    height: 340px;
  }
  .port2 {
    height: 260px;
  }
  .port3 {
    height: 260px;
  }
}
@media (max-width: 1083px) {
  .img-left {
    height: 250px;
    width: auto;
  }
  .img-right {
    height: 380px;
  }
  .card2,
  .card3 {
    width: 450px;
    align-items: center;
    justify-content: center;
    margin: 30px 10px;
    padding-top: 0px;
    padding-left: 0px;
  }
  .name-text-part2,
  .name-text-part3 {
    font-size: 40px;
  }
  .para-text-part2,
  .para-text-part3 {
    font-size: 15px !important;
  }
  .span-text2,
  .span-text3 {
    font-size: 14px;
  }
  .imgPartSsmall2,
  .imgPartSsmall3 {
    display: none;
  }
  .text-part-2,
  .text-part-3 {
    width: 350px;
  }
}
@media (max-width: 1067px) {
  .lhighl {
    margin-left: -240px;
  }
}
@media (max-width: 1035px) {
  .img-left {
    width: 400px;
    height: auto;
  }
  .spline-char {
    margin-right: 0px;
  }
}
@media (max-width: 1019px) {
  .lhighl {
    margin-left: -280px;
  }
}
@media (max-width: 987px) {
  .above-links-sub {
    margin-left: 60px;
  }
  .lhighl {
    margin-left: -300px;
  }
}
@media (max-width: 981px) {
  .img-left {
    height: 220px;
    width: auto;
  }
  .img-right {
    height: 350px;
  }
  .card2,
  .card3 {
    width: 420px;
  }
  .text-part-2,
  .text-part-3 {
    width: 300px;
  }
  .toggle-img {
    display: block;
  }
  .subhs-img-bottom {
    margin-top: -10px;
  }
  .port11 {
    height: 400px;
  }
  .port22 {
    height: 200px;
    margin-top: 15px;
  }
  .port33 {
    height: 200px;
    margin-top: 15px;
  }
  .subhs-img {
    display: none;
  }
}
@media (max-width: 928px) {
  .above-links-sub {
    margin-left: 50px;
  }
  .lhighl {
    margin-left: -300px;
  }
  .img-left {
    margin-left: 70px;
  }
  .spline-char {
    margin-right: 0px;
  }
}
@media (max-width: 920px) {
  .card2,
  .card3 {
    width: 380px;
  }
  .text-part-2,
  .text-part-3 {
    width: 300px;
  }
}
@media (max-width: 904px) {
  .img-left {
    height: 200px;
    width: auto;
  }
  .img-right {
    height: 320px;
  }
}
@media (max-width: 895px) {
  .above-links {
    flex-direction: column;
    height: auto;
    text-align: center;
  }
  .above-links-sub {
    padding-left: 0;
    margin-left: 0;
  }
  .lhighl {
    display: none;
  }
  .lhighl1 {
    display: block;
    height: 400px;
    margin: 50px 0;
  }
}

@media (max-width: 868px) {
  .img-left {
    width: 350px;
    height: auto;
  }
  .spline-char {
    margin-right: -30px;
  }
}
@media (max-width: 823px) {
  .body-wave{
    height: 25vh;
    margin-top: -70px;
  }
  .next-cards {
    flex-direction: column;
  }
  .work-in {
    margin-left: 0;
  }
  .heading-work {
    text-align: center;
  }
  .my-work {
    text-align: center;
  }
}
@media (max-width: 811px) {
  .img-left {
    width: 320px;
    height: auto;
  }
}
@media (max-width: 783px) {
  .Home-top {
    flex-direction: column;
    height: 160vh;
  }
  .hover-on-me{
    display: none;
  }
  .spline-div {
    margin-top: -520px;
    height: 300px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img-left {
    margin: 0;
    padding: 0;
    height: 230px;
    width: auto;
    margin-top: -100px;
  }
  .spline-char {
    transform: scale(0.8);
    margin-left: -120px;
  }
}
@media (max-width: 755px) {
  .what-i-do {
    width: 660px;
  }
}
@media (max-width: 704px) {
  .port22 {
    height:200px;
  }
  .port33 {
    height: 200px;
  }
}
@media (max-width: 661px) {
  .what-i-do {
    width: 600px;
  }
  .heading-work-wid {
    font-size: 70px;
  }
}
@media (max-width: 597px) {
  .what-i-do {
    width: 550px;
  }
}
@media (max-width: 560px) {
  .spline-char {
    transform: scale(0.7);
    margin-left: -120px;
  }
}
@media (max-width: 551px) {
  .what-i-do {
    width: 450px;
  }
}
@media (max-width: 510px) {
  .phone1,
  .phone2 {
    display: none;
  }
  .card-work {
    padding: 50px;
    height: 410px;
  }
  .name-text-part {
    font-size: 40px;
  }
  .para-text-part {
    font-size: 17px;
  }
  .card2,
  .card3 {
    justify-content: center;
    align-items: center;
  }
  .card2,
  .card3 {
    width: 370px;
    border-radius: 40px;
    margin: 30px 10px;
    padding-top: 0px;
    padding-left: 0px;
  }
  .imgPartSsmall2,
  .imgPartSsmall3 {
    display: none;
  }

  .heading-work {
    font-size: 50px;
  }
}
@media (max-width: 500px) {
  .card-work {
    width: 380px;
  }
}
@media (max-width: 478px) {
  .img-left {
    width: 350px;
    height: auto;
  }
  .spline-char {
    transform: scale(0.65);
  }
}
@media (max-width: 451px) {
  .what-i-do {
    width: 400px;
  }
  .heading-work-wid {
    font-size: 65px;
  }
}
@media (max-width: 481px) {
  .footer {
    height: auto;
  }
  .dot-foot {
    display: none;
  }
  .links {
    flex-direction: column;
  }
  .foots-foot {
    width: auto;
    margin: 10px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .gara {
    display: none;
  }
  .ht {
    padding-top: 13px;
  }
}
@media (max-width: 463px) {
  .port11 {
    height: 330px;
  }
  .port22 {
    height: 168px;
  }
  .port33 {
    height: 168px;
  }
}
@media (max-width: 459px) {
  .mlh-sc {
    margin: auto;
    width: 350px;
    margin-bottom: 60px;
  }
}
@media (max-width: 446px) {
  .spline-char {
    transform: scale(0.6);
  }
}
@media (max-width: 442px) {
  .card-work {
    padding: 40px;
  }
  .lhighl1 {
    height: 320px;
  }
}
@media (max-width: 431px) {
  .Home-top {
    height: 120vh;
  }
  .spline-char {
    display: none;
  }
  .img-right {
    display: block;
    margin-top: 300px;
  }
  .splineLoad{
    display: none;
  }
}
@media (max-width: 410px) {
  .img-left {
    width: 330px;
    height: auto;
  }
  .spline-char {
    transform: scale(0.55);
    margin-left: -315px;
  }
}
@media (max-width: 401px) {
  .card-work {
    width: 360px;
  }
  .card2,
  .card3 {
    width: 360px;
  }
  .heading-work-wid {
    line-height: 70px;
  }
  .heading-work-wid {
    height: 50vh;
  }
}
@media (max-width: 400px) {
  .heading-work-wid{
    position: relative;
    z-index: 50;
  }
  .body-wave{
    height: 1vh;
    margin-top: -80px;
  }
  .Home-top {
    height: 100vh;
  }
  .img-right{
    margin-top: 350px;
    transform: scale(0.8);
  }
  .spline-char {
    margin-top: 140px;
    transform: scale(0.45);
  }
  .spline-div {
    width: 320px;
  }
  .what-i-do {
    width: 380px;
  }
  .heading-work-wid {
    font-size: 60px;
  }
}
@media (max-width: 391px) {
  .img-left {
    height: 170px;
    width: auto;
  }
  .img-right {
    display: block;
  }
  .port11 {
    height: 300px;
  }
  .port22,
  .port33 {
    height: 150px;
  }
}
@media(max-width:381px){
  .what-i-do {
    width: 330px;
  }
  .heading-work-wid {
    font-size: 50px;
  }
}
@media(max-width:375px){
  .button-home-exp{
    transform: scale(0.85);
  }
}

